/* login.css */

.flexgrid-demo .box {
  /* background-color: var(--surface-e); */
  text-align: center;
  padding-top: 6rem;
  text-align: -webkit-center;
}

.custom-password-field input {
  width: inherit !important;
}

.custom-button {
  width: 25% !important;
}

.custom-text-align {
  text-align: initial;
}

@media screen and (min-width: 1400px) {
  .lg\:w-6 {
    width: 35% !important;
  }
}

@media screen and (min-width: 1400px) {
  .surface-card.p-4.shadow-2.border-round.w-full.lg\:w-6 {
    width: 30% !important;
  }
}

.custom-button-color .p-button {
  background: #04c8eb !important;
  border-color: #04c8eb !important;
}
