.ant-steps-item-icon {
  line-height: 29px;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #ffff;
}

.ant-steps-icon {
  color: #000000 !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #037eea;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #037eea;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #000000 !important;
  color: #037eea;
}

.ant-steps-item-tail::after {
  background-color: #e2cece !important;
}

.custom-form-design {
  width: 100%;
}

.custom-form-design {
  .ant-row .ant-form-item {
    margin: 10px !important;
  }
}

.custom-form-design {
  .ant-row .ant-row .ant-form-item {
    margin: 0px !important;
  }
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  border-color: #037eea !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-variant: tabular-nums;
  font-size: 14px !important;
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-variant: tabular-nums;
  font-size: 14px !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #037eea !important;
}

.steps-action {
  display: flex;
  justify-content: center;
  .ant-btn-primary {
    background-color: #037eea;
    border: none;
  }
}

.steps-content {
  background-color: white;
  border-radius: 15px;
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  //
}

.container-for-downloadBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  :hover {
    color: white !important;
    border-color: #04c8eb !important;
    background: #04c8eb !important;
  }
}

.download-btn {
  // max-width: 12vw;
  margin-top: 1vw;
  margin-bottom: 5vh;
  height: 7vh;
  width: 13vw !important;
  background-color: #037eea;
  font-size: 17px;
  font-weight: 700;
  padding: 10px;
  border-radius: 15px;
  color: white;
  :hover {
    color: white !important;
    border-color: #037eea !important;
    background: #037eea !important;
  }
}

.span-for-download {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6vw;
  font-weight: 700;
  font-size: 18px;
}
