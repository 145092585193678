.p-dialog {
  width: 670px;
}

/* For medium screens (like tablets) */
@media (max-width: 1024px) {
  .p-dialog {
    width: 80vw;
    max-width: 670px;
    /* Ensure max width doesn't exceed 670px */
  }
}

/* For small screens (like mobile devices) */
@media (max-width: 768px) {
  .p-dialog {
    width: 90vw;
    min-width: 90vw;
    /* Set min-width to 90vw on small screens */
    max-width: 670px;
    /* Ensure it doesn't exceed 670px */
  }
}

.p-dialog-content {
  padding: 10px;
  overflow-x: auto;
  /* Horizontal scroll */
  overflow-y: auto;
  /* Vertical scroll (if needed) */
  flex-grow: 1;
  /* Takes remaining space */
  white-space: nowrap;
  /* Prevent content from wrapping */
}