// .menu-item-style {
//     display: flex;
//     align-items: center;
//     justify-content: space-evenly;
//     margin-top: 2rem;
//     //   background-color: aqua;
//     width: 100%;
//     font-size: 17px;
//     padding: 1.5rem;
// }

// .menu-item-style:hover {
//     color: #037eea;
//     border-left: 0.3rem solid #037eea;
//     background-color: #f5fcf3;
// }

// .self-service-layout {
//     background-color: #037eea !important;
//     padding: 0px 24px;
// }

// .ant-page-header {
//     background-color: white !important;
//     padding: 0px 24px !important;
//     // border-radius: 20px;
// }

// .top-bar-main-con {
//     display: flex;
//     margin-top: 1vh;
//     position: fixed;
//     width: 100%;
//     // border: 1px solid red;
//     // justify-content: flex-end;
//     // width: 100vw;
// }

// .site-page-header-ghost-wrapper {
//     // border: 2px solid green;
//     float: right;
//     width: 100% !important;
//     // margin: -1px 3%;
//     // z-index: -1;
//     // margin-right: 10% !important;
// }

// .angle-icon-adjuster {
//     border: 1px solid #855414;
//     border-radius: 50%;
//     .pi-user {
//         font-size: 15px;
//         color: #855414;
//         padding: 5px;
//     }
// }

// .ant-layout-content {
//     margin-top: 0px !important;
// }

// //////////////////////////////////new layout styling
// .layout-menu-container {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
// }

// .logo-con {
//     align-self: center;
//     background-color: #037eea;
// }

// .img-adjuster {
//     height: 63px;
// }

// .line {
//     border: 1px solid gray;
//     width: 100%;
//     background-color: gray;
//     margin-top: 7px;
// }

.menu-item-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  //   background-color: aqua;
  width: 100%;
  font-size: medium;
  padding: 1.5rem;


}

.menu-item-selected {
  color: #037eea;
  border-left: 0.3rem solid #037eea;
  background-color: #f5fcf3;
}

.menu-item-style:hover {
  color: #037eea;
  border-left: 0.3rem solid #037eea;
  background-color: #f5fcf3;
}

.test {
  .layout-topbar {
    height: 7rem !important;
  }
}

.tbarmaincon {
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.tbar-container {
  display: flex;
  width: 130px;
  justify-content: space-around;
  align-items: center;
}

.iconadjuster {
  align-self: center;
}

.usernamespan {
  font-size: 13px;
  margin: 0px 5px;
}