// @import "../assets/demo/BlockViewer.scss";
// @import "../assets/demo/Documentation.scss";
// @import "../assets/demo/MediaDemo.scss";
// @import "../assets/demo/TableDemo.scss";
// @import "../assets/demo/TimelineDemo.scss";
// @import "../assets/demo/Badge.scss";

// @media (min-width: 992px) {
//   // .layout-topbar {
//   //   position: fixed;
//   // }
//   .layout-sidebar {
//     width: 14rem;
//     height: 100%;
//     border-radius: 0;
//     //    height: calc(100vh - 7rem);
//     z-index: 0;
//     top: 0rem;
//     left: 0rem;
//     padding: 0;
//     .layout-menu-container {
//       margin-top: 6rem;
//     }
//     .layout-menu li a {
//     }
//   }

//   .layout-wrapper.layout-static .layout-main-container {
//     margin-left: 120px;
//   }

//   .layout-main-container {
//     padding: 6rem 1rem 2rem 4rem;
//   }
// }

// @media (max-width: 991px) {
//   .layout-wrapper.layout-static .layout-main-container {
//     padding-left: 1rem;
//   }

//   .layout-main-container {
//     padding: 6rem 1rem 1rem 1rem;
//   }
// }

// .layout-topbar .layout-topbar-logo {
//   width: 190px;
// }

// @media (min-width: 992px) {
//   .layout-wrapper.layout-static.layout-static-sidebar-inactive
//     .layout-main-container {
//     margin-left: -10px;
//   }
// }

// .self-service-layout {
//   background-color: white !important;
//   display: flex;
// }

// .steps-content {
//   // min-height: 200px;
//   margin-top: 16px;
//   // padding-top: 80px;
//   text-align: center;
//   background-color: #fafafa;
//   // border: 1px solid #fbb618;
//   border-radius: 2px;
//   margin-left: 3% !important;
//   .ant-form {
//     max-width: 80%;
//   }
//   .drag-adjuster {
//     height: 35vh;
//   }
// }

// .ant-upload.ant-upload-drag .ant-upload-btn {
//   border: 1px dashed #fbb618;
// }

// .ant-steps {
//   // margin: 0 11%;
//   // margin-right: 2% 11%;
//   //  margin-left: 3%;
//   max-width: 82vw;
//   // margin-right: 3% !important;
// }

// .steps-action {
//   margin-top: 24px;
// }
// // .layout-sidebar {
// //   width: 181px !important;
// //   height: 100%;
// //   border-radius: 0;
// //   //    height: calc(100vh - 7rem);
// //   z-index: 0;
// //   top: 0rem;
// //   left: 0rem;
// //   padding: 0;
// //   // border: 1px solid red;
// //   display: flex;
// //   flex-direction: column;
// //   .layout-menu-container {
// //     width: 60%;
// //     margin-right: 0px;
// //   }
// // }

// .layout-wrapper.layout-static .layout-main-container {
//   margin-left: 120px;
// }

// .layout-main-container {
//   padding: 3rem 1rem 1rem 4rem;
//   // display: flex;
//   width: 75vw;
//   // justify-content: flex-start;
//   margin-left: 178px;
// }

// /////////////////////////////////////////////////////////////////////////////
// //////////////////////////////////new layout designs////////////////
// /////////////////////////////////////////////////////////////////////////
// .main-dv {
//   width: 100%;
//   // border: 1px solid red;
//   display: flex;
//   height: auto;
// }
// .menu-container-left {
//   width: 13%;
//   height: 100vh;
//   position: static;
//   // border: 3px solid blue;
// }
// .bar-and-content-container {
//   width: 87%;
//   // border: 1px solid yellow;
// }

// .main-dv > .menu-container-left:hover .bar-and-content-container {
//   max-width: 90% !important;
// }
// .layout-sidebar {
//   position: fixed;
//   margin-top: 1vh;
//   width: 240px;
//   height: 70vh;
//   z-index: 999;
//   overflow-y: auto;
//   -ms-user-select: none;
//   -webkit-user-select: none;
//   user-select: none;
//   top: 7rem;
//   left: 1rem;
//   background-color: white;
//   border-radius: 12px;
//   padding: 1.5rem;
//   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
//     0px 1px 4px rgba(0, 0, 0, 0.08);
// }
// //////////////////////////////////////////////////////////////////////////////
// @media (max-width: 991px) {
//   .layout-wrapper.layout-static .layout-main-container {
//     padding-left: 1rem;
//   }
// }

// .layout-topbar .layout-topbar-logo {
//   width: 190px;
// }

// @media (min-width: 992px) {
//   .layout-wrapper.layout-static.layout-static-sidebar-inactive
//     .layout-main-container {
//     margin-left: -10px;
//   }
// }

.layout-main {
  background-color: white;
  border-radius: 15px;
  padding: 15px;
}
// .body {
//   background-color: #edf1f5;
// }

.p-datatable .p-datatable-tbody > tr > td {
  text-align: center !important;
}

.p-datatable .p-column-header-content {
  justify-content: center !important;
}
