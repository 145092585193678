.input {
  border: none;
  padding: 0.2rem;
  overflow-wrap: break-word;
}

.field {
  margin-bottom: 0;
  font-size: 1rem !important;
}

.field > label {
  margin-bottom: 0;
}

.p-divider.p-divider-horizontal {
  margin: 3px 0;
  padding: 0 1.25rem;
}

.Uploadbtn .ant-upload.ant-upload-select-picture-card {
  width: 70px;
  height: 70px;
  margin-top: 1rem;
}

.truncate {
  min-width: 0;
  /* or some value */
}

.truncate p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttonIn {
  position: relative;
}

.customBtn {
  position: absolute;
  bottom: 0;
  border-radius: 5px;
  right: 0px;
  /* z-index: 1; */
  border: none;
  /* top: 2px; */
  height: 35px;
  cursor: pointer;
  color: white;
  background-color: #1e90ff;
  /* transform: translateX(2px); */
}

.scrollbar-pink::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1); */
  background-color: #d5d5d5;
}

.scrollbar-pink {
  scrollbar-color: #d5d5d5 #f5f5f5;
  scrollbar-width: thin;
}

.thin::-webkit-scrollbar {
  width: 6px;
}

.p-galleria .p-galleria-item-nav {
  background-color: #b9b2b2e3;
}

.p-dialog .p-dialog-content {
  background: #F8F9FA;
  color: #495057;
  padding: 1.5rem 2rem 1.5rem;
}
.label-created-date{
  display: flex;
  width: 30rem;
  justify-content: space-between;
}

.date-input {
  border: none;
  padding: 0.2rem;
  overflow-wrap: break-word;
}

@media (max-width: 1550px) {
  .date-input {
    border: none;
    padding: 0.2rem;
    overflow-wrap: break-word;
    margin-right: 25%
  }
}

@media (max-width: 990px) {
  .date-input {
    border: none;
    padding: 0.2rem;
    overflow-wrap: break-word;
    margin-right: 36%;
  }
}