.bg-header-user{
  background-color: #fff;
  text-align: left !important;
  font-weight: bold;
  font-size: 13px
}

.bg-header-time{
  background-color: #fff;
  text-align: center !important;
  font-weight: bold;
}

.bg-green{
  background-color: #e2efda !important;
}

.bg-orange{
  background-color: #fce4d6 !important;
}

.bg-yellow{
  background-color: #fff2cc !important;
}

.bg-blue{
  background-color: #ddebf7 !important;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > .bg-header-user > td{
   text-align: left !important ;
   padding: 5px 10px;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > .border-style > td{
  border-bottom: 1px solid gray !important;
}

.header-user{
  font-weight: bold;
}

.total-style{
  font-weight: bold;
}

.border-style{
  border-bottom: 1px solid gray !important;
}